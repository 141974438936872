<template>
  <div>
    <div class="topBg positionRelative">
      <div style="height: var(--35);"></div>
      <div class="flex_center">
        <img style="width: var(--52);height: var(--16);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/56b42842-11e3-42bb-8a05-6a73cfd4bc0e.png">
        <div class="font-size32 font-colorFFFFFF line-height48">
          参与条件说明
        </div>
        <img style="width: var(--52);height: var(--16);transform: rotate(180deg);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/56b42842-11e3-42bb-8a05-6a73cfd4bc0e.png">
      </div>
      <div style="height: var(--25);"></div>
      <div class="font-size28 line-height48 flex_center" style="color: #FEFEFF;">
        参与储存X币的用户均可以参与瓜分
      </div>
      <div style="height: var(--20);"></div>
      <div style="padding-left: var(--54);padding-right: var(--54);">
        <div class="flex_able">
          <div class="circlePoint"></div>
          <div class="margin-left8 font-size26 line-height48" style="color:#4CDBF6;">注意:</div>
        </div>
        <div class="font-size24 line-height50" style="color:rgba(254, 254, 255, 0.8);">
          瓜分的数量取决于储存后您每日领取的储存火箭值，非商城购物获得的火箭值
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {

  },
  methods: {},
}
</script>

<style scoped>
.topBg {
  width: var(--656);
  height: var(--383);
  background-size: 100% 100%;
  background-image: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/0f719d5e-46c0-409d-b2d1-33e3fe24e781.png");
}

.circlePoint {
  width: var(--8);
  height: var(--8);
  background: #4CDBF6;
  border-radius: 50%;
}
</style>
