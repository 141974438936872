<template>
  <div class="box">
    <div class="topBg positionRelative">
      <div v-if="status == 0" @click="queryRocketPartitionOrder1"
           class="buttonBg font-size57 font-weightBold font-color1B1B1B flex_center positionAbsolute"
           style="bottom: var(--138);left: 50%;transform: translate(-50%);">
        立即瓜分
      </div>
      <div v-if="status == 1"
           class="buttonBg font-size57 font-weightBold font-colorFFFFFF flex_center positionAbsolute"
           style="bottom: var(--138);left: 50%;transform: translate(-50%);filter: grayscale(100%);">
        已瓜分
      </div>
      <div class="font-size26 font-colorFFFFFF positionAbsolute flex_center line-height48"
           style="bottom: var(--39);width: 100vw;left: 50%;transform: translate(-50%);
           padding-left: var(--79);padding-right: var(--80);text-align: center;color:rgba(254, 254, 255, 0.8);">
        瓜分到的奖励将会发放至TP钱包DApp中，您可在DApp中查看与提取
      </div>
    </div>
    <div v-if="false" class="flex_able" style="border-radius: var(--20);width: var(--655);
    background:#FFFFFF;padding-left: var(--30);margin-left: auto;margin-right: auto;
          padding-right: var(--30);">
      <van-field ref="textInput" style="flex: 1" v-model="withdrawalAddress"
                 placeholder="请输入火币账号"
                 clearable
                 placeholder-class='placeholder-input'/>

      <div @click="handlePaste" class="margin-left16 font-size28 flex_center"
           style="height: 100%;color:#2396FF;">粘贴
      </div>
    </div>
    <div v-if="false" @click="updateWalletAddress"
         class="tiQuClass font-size32 font-weightBold font-colorFFFFFF flex_center margin-top22">
      提取
    </div>
    <div class="margin-top40 margin-bottom40" style="margin-left: auto;margin-right: auto;">
      <carve-up-rule/>
    </div>
    <van-popup round v-model="showDialog" style="background: transparent;">
      <div class="positionRelative" style="height: var(--650);width: 100vw;">
        <div class="dialogBg positionAbsolute flex_able flex-directionColumn"
             style="top: var(--50);left: 50%;transform: translate(-50%);">
          <div class="flex_center" style="margin-top: var(--83);">
            <span class="font-size53 font-weightBold" style="color:#49A6E7;">+{{ rewardUserAmount }}</span>
            <span class="font-size34 font-weightBold font-color1B1B1B margin-left10">{{ assetTypeName }}</span>
          </div>
          <img class="margin-top46" style="width: var(--145);height: var(--145);"
               src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/de5e0608-eefa-4cce-8c60-ece9d0981104.png"/>
          <div @click="showDialog = false"
               class="sureClass flex_center font-size30 font-weightBold font-colorFFFFFF margin-top66">
            我知道了
          </div>
        </div>
        <img class="positionAbsolute"
             style="width: var(--491);height: var(--107);top: 0;left: 50%;transform: translate(-50%);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/f227327c-52fc-48ff-855b-6be86ec77691.png">

      </div>
    </van-popup>
    <van-popup v-model="popupExplainRule" style="background: transparent;">
      <div class="popupBox">
        <div class="popupClose_text">提示</div>
        <div style="display: flex;flex-direction: column">
          <div
              style="font-size:var(--26);color:#1b1b1b;font-family: PingFangSC-Regular, PingFang SC;max-height: var(--500);overflow: auto;line-height: var(--45);"
              v-html="activityRole"></div>
          <div class="popupBtn" @click="popupExplainRule = false">我知道了</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {Toast} from "vant";
import {Api} from "@/utils/net/Api";
import EncryptUtils from "@/utils/EncryptUtils";
import CarveUpRule from "./components/carveUpRule.vue";

export default {
  components: {CarveUpRule},
  data() {
    return {
      status: 0,
      rewardUserAmount: "",
      withdrawalAddress: "",
      showDialog: false,
      popupExplainRule: false,
      activityRole: "",
      assetTypeName: ""
    }
  },
  created() {
    try {
      //设置标题
      document.title = "火箭值瓜分"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#151520',
        textColor: '#FFFFFF',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "false",
        statusBarBgColor: "#151520",
        showStatusBar: "true"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    //弹窗内容
    let activityRole = "请您先前往TP钱包关联DApp!\n" +
        "操作步骤如下:\n" +
        "1.打开TP钱包，点击发现，并粘贴http://xsj.shangans.online此链接到搜索框内\n" +
        "2.点击右上角连接钱包按钮\n" +
        "3.在蓝色框内找到设置按钮并点击\n" +
        "4.输入要关联的X世界账号和登录密码即可\n若还有疑问，可在社区提问"
    this.activityRole = activityRole?.replace(/\n/g, "<br>")
    // this.popupExplainRule = true
    //首次进来
    this.queryRocketPartitionOrder0(0)
  },
  methods: {
    handlePaste() {
      //调用原生获取剪切板内容
      window.Android.checkClipboardContent()
    },
    queryRocketPartitionOrder0() {
      let params = {
        readStatus: 0
      }
      Api.queryRocketPartitionOrder(params).then((result) => {
        console.log('data...', result)
        if (result.code == 100) {
          //更新状态
          this.status = result?.data?.entity?.readStatus ?? 0
        } else {
          Toast(result.msg)
        }
      })
    },
    queryRocketPartitionOrder1() {
      let params = {
        // readStatus: 1
      }
      Api.queryRocketPartitionOrderGetReward(params).then((result) => {
        console.log('data...', result)
        if (result.code == 100) {
          let statusDialog = result?.data?.status
          if (statusDialog == 1) {
            this.popupExplainRule = true
            return
          }
          this.status = result?.data?.entity?.readStatus ?? 0
          if (this.status == 0) {
            Toast(result.msg)
          }
          if (this.status == 1) {
            this.rewardUserAmount = result?.data?.entity?.rewardUserAmount ?? "0"
            this.assetTypeName = result?.data?.entity?.assetTypeName ?? "0"
            //说明瓜分成功
            //显示弹窗
            this.showDialog = true
            //成功后再调用一次借口
            this.queryRocketPartitionOrder0()
          }
        } else {
          Toast(result.msg)
        }
      })
    },
    updateWalletAddress() {
      if (EncryptUtils.isEmpty(this.withdrawalAddress)) {
        Toast("请输入火币账号")
        return;
      }
      let params = {
        walletAddress: this.withdrawalAddress
      }
      Api.updateWalletAddress(params).then((result) => {
        console.log('data...', result)
        if (result.code == 100) {
          console.log("请求成功")
          Toast("提取成功")
        } else {
          Toast(result.msg)
        }
      })
    },
  },
}
</script>

<style scoped>
.box {
  width: 100vw;
  height: 100vh;
  background: #0D0D0D;
  display: flex;
  flex-direction: column;
}

.topBg {
  width: 100vw;
  height: 123.2vw;
  background-size: 100% 100%;
  background-image: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/38c63b23-c19d-412f-94b2-6dfb7125dee3.png");
}

.buttonBg {
  width: var(--535);
  height: var(--148);
  background-size: 100% 100%;
  background-image: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/c13cf187-83e9-41f6-8b14-4a35e95749fc.png");
}

/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #999999;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--96);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1b1b1b;
  font-size: var(--28);
  font-weight: 400;
  line-height: var(--40);
}

::v-deep .van-cell::after {
  left: 0;
  border: none;
}

.tiQuClass {
  margin-left: auto;
  margin-right: auto;
  width: var(--655);
  height: var(--88);
  background: #2396FF;
  border-radius: var(--20);
}

.dialogBg {
  width: var(--572);
  height: var(--610);
  background: #FFFFFF;
  border-radius: var(--18);
}

.sureClass {
  width: var(--309);
  height: var(--73);
  background: #2396FF;
  border-radius: var(--100);
}

/* 规则说明 */
.popupBox {
  width: var(--650);
  background: #FFFFFF;
  padding: 0 4vw 4vw 4vw;
  box-sizing: border-box;
  word-break: break-all;
  border-radius: 4vw;
}

.popupClose_text {
  font-size: var(--36);
  color: #392710;
  font-weight: bold;
  text-align: center;
  padding: var(--39) 0 var(--20) 0;
  box-sizing: border-box;
}

.popupBtn {
  width: var(--529);
  line-height: var(--80);
  text-align: center;
  font-size: var(--30);
  color: #fff;
  background: #E5A757;
  border-radius: var(--40);
  margin: var(--20) auto auto auto;
}
</style>
