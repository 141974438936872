import { render, staticRenderFns } from "./carveUpNewB.vue?vue&type=template&id=108c9388&scoped=true"
import script from "./carveUpNewB.vue?vue&type=script&lang=js"
export * from "./carveUpNewB.vue?vue&type=script&lang=js"
import style0 from "./carveUpNewB.vue?vue&type=style&index=0&id=108c9388&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108c9388",
  null
  
)

export default component.exports